<template>
  <a-modal
    title="入库"
    :width="680"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="库位">
              <a-select
                option-label-prop="tt"
                :show-arrow="true"
                :filter-option="false"
                :not-found-content="null"
                show-search
                v-decorator="[
                  'cargo_space_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="库位名称"
                :default-active-first-option="false"
                @change="handleWmsCargoSpaceChange"
              >
                <a-select-option v-for="d in dataSource_wms_cargo_space" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { cargo_space_list } from '@/api/c_wms_cargo_space'

// 表单字段
const fields = ['id', 'cargo_space_id']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      fetching: false,
      form: this.$form.createForm(this),
      dataSource_wms_cargo_space: [],
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        }
      }
    }
  },
  mounted () {
    // 防止表单未注册
    setTimeout(() => {
      fields.forEach(v => this.form.getFieldDecorator(v))
      // // 当 model 发生改变时，为表单设置值
      this.model && this.form.setFieldsValue(pick(this.model, fields))
      this.$watch('model', () => {
        this.model && this.form.setFieldsValue(pick(this.model, fields))
      })
      this.handleWmsCargoSpaceSearch(this.model.warehouse_id, this.model.supplier_id)
    })
  },
  methods: {
    handleWmsCargoSpaceSearch (value, supplier_id) {
      cargo_space_list({ warehouse_id: value, supplier_id: supplier_id }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_cargo_space = result
      })
    },
    handleWmsCargoSpaceChange (value) {
      console.log(value)
      const objneo = this.dataSource_wms_cargo_space.find((item) => {
        return item.id === value
      })
      console.log(objneo, 'objneo', this.dataSource_wms_cargo_space)
      const obj = Object.assign({}, objneo)
      console.log(obj)
    }
  }
}
</script>
