<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" >
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="仓库">
              <a-select
                :not-found-content="warehouse_fetching ? undefined : null"
                :default-active-first-option="false"
                :filter-option="false"
                placeholder="选择仓库"
                show-search
                allow-clear
                v-model="queryParam.warehouse_id"
                @search="this.warehouseSearch"
                @change="handleWmsWarehouseChange"
              >
                <a-spin v-if="warehouse_fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in dataSource_wms_warehouse" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="条形码">
              <a-input v-model="queryParam.barcode" placeholder="请输入条形码" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="批次">
              <a-input v-model="queryParam.batch" placeholder="请输入批次" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status" placeholder="请选择" allow-clear>
                <a-select-option
                  :value="key"
                  v-for="(val, key) of this.$Dictionaries.commodity_purchase_status"
                  :key="key">{{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-row :gutter="24">
        <a-col :md="2" :sm="24">
          <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
        </a-col>
        <a-col :md="2" :sm="24" v-if="false">
          <a-button type="primary" @click="handleInWarehouse">批量入库</a-button>
        </a-col>
        <a-col :md="2" :sm="24">
          <a-button type="primary" @click="handleImport">
            <a-icon type="upload" />
            导入
          </a-button>
        </a-col>
        <a-col :md="2" :sm="24" v-if="false">
          <a-button type="primary" icon="plus" @click="handleAddCustom">新建套盒</a-button>
        </a-col>
      </a-row>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 120px">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 120px">
        {{ text }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)" v-if="record.status === 2">入库</a>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
            v-if="record.status === 1"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible.sync="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <commodity-purchase-form
      ref="createCommodityPurchaseModal"
      v-if="purchase_form_visible"
      :visible.sync="purchase_form_visible"
      :loading="confirmLoading"
      :model="purchase_form_mdl"
      @cancel="handleInCancel"
      @ok="handleInOk" />
    <goods-purchase-import-form
      ref="purchaseImportModal"
      v-if="purchase_import_form_visible"
      :visible.sync="purchase_import_form_visible"
      :loading="purchaseImportConfirmLoading"
      @cancel="handleImportCancel"
      @ok="handleImportOk" />
    <cargo-space-form
      ref="cargoSpaceModal"
      v-if="cargo_space_form_visible"
      :visible.sync="cargo_space_form_visible"
      :loading="cargo_spaceConfirmLoading"
      :model="cargo_space_form_mdl"
      @cancel="handleCargoSpaceCancel"
      @ok="handleCargoSpaceOk" />
  </div>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '@/views/c-wms/goods_purchase/modules/CreateForm'
import CommodityPurchaseForm from '@/views/c-wms/goods_purchase/modules/GoodsPurchaseForm'
import PurchaseDeclarationForm from '@/views/c-wms/goods_purchase/modules/PurchaseDeclarationForm'
import GoodsPurchaseImportForm from '@/views/c-wms/goods_purchase/modules/GoodsPurchaseImportForm'
import CargoSpaceForm from '@/views/c-wms/goods_purchase/modules/CargoSpaceForm'
import {
  goods_purchase_create,
  goods_purchase_delete,
  goods_purchase_list,
  goods_purchase_update,
  goods_purchase_import,
  custom_goods_purchase_create, goods_purchase_declaration_list
} from '@/api/c_wms_goods_purchase'
import { warehouse_list } from '@/api/c_wms_warehouse'
import { Base64 } from 'js-base64'
import { goods_inventory_create } from '@/api/c_wms_period_inventory'
import debounce from 'lodash/debounce'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    CommodityPurchaseForm,
    PurchaseDeclarationForm,
    GoodsPurchaseImportForm,
    CargoSpaceForm
  },
  data () {
    this.warehouseSearch = debounce(this.handleWarehouseSearch, 800)
    return {
      loading: false,
      confirmLoading: false,
      purchaseDeclarationConfirmLoading: false,
      purchaseImportConfirmLoading: false,
      cargo_spaceConfirmLoading: false,
      purchase_declaration_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      warehouse_fetching: false,
      purchase_form_visible: false,
      custom_purchase_create_visible: false,
      purchase_import_form_visible: false,
      cargo_space_form_visible: false,
      purchase_form_mdl: null,
      purchase_declaration_form_mdl: undefined,
      cargo_space_form_mdl: undefined,
      allin_cargo_space: false,
      dataSource_wms_warehouse: [],
      dataSource_wms_commodity: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 创建窗口控制
      purchase_declaration_visible: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '供应商',
          dataIndex: 'supplier'
        },
        {
          title: '仓库',
          dataIndex: 'warehouse'
        },
        {
          title: '货品名称',
          align: 'center',
          dataIndex: 'goods_name'
        },
        {
          title: '货品条形码',
          align: 'center',
          dataIndex: 'barcode'
        },
        {
          title: '批次',
          align: 'center',
          dataIndex: 'batch'
        },
        {
          title: '采购价格',
          align: 'center',
          dataIndex: 'price',
          scopedSlots: { customRender: 'money_render' }
        },
        {
          title: '购买税率',
          align: 'center',
          dataIndex: 'buy_tax_rate',
          customRender: (text) => text + '%'
        },
        {
          title: '销售税率',
          align: 'center',
          dataIndex: 'sell_tax_rate',
          customRender: (text) => text + '%'
        },
        {
          title: '采购数量',
          align: 'right',
          dataIndex: 'count',
          scopedSlots: { customRender: 'count_render' }
        },
        {
          title: '实际数量',
          align: 'right',
          dataIndex: 'actual_count',
          scopedSlots: { customRender: 'count_render' }
        },
        {
          title: '截至日期',
          align: 'center',
          dataIndex: 'expiration_date',
          customRender: (text) => text === '1970-01-01' ? '-' : text
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.commodity_purchase_status[text] || '无'
        },
        {
          title: '操作',
          align: 'center',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return goods_purchase_list(Object.assign(parameter, this.queryParam, { commodity_id: this.$route.params.commodity_id }))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.purchase_form_mdl = null
    console.log(this.$route.query.record)
    if (this.$route.query.record !== undefined) {
      this.purchase_form_mdl = JSON.parse(Base64.decode(this.$route.query.record))
      this.purchase_form_mdl.goods_type = this.$route.query.goods_type
      console.log(this.purchase_form_mdl)
    } else {
      this.purchase_form_mdl = null
    }
    this.handleWarehouseSearch(undefined)
  },
  computed: {
    rowSelection () {
      const that = this
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          that.onSelectChange(selectedRowKeys, selectedRows)
        }
      }
    }
  },
  methods: {
    handleAdd () {
      if (this.$route.params.commodity_id) {
        this.purchase_form_visible = true
      } else {
        this.mdl = null
        this.visible = true
      }
    },
    handleAddCustom () {
      this.$router.push({
        path: '/zb/wms/goods_purchase_custom_goods'
      })
    },
    handleInWarehouse () {
      if (this.selectedRows.length === 0) {
        this.$message.warning('选择入库数据')
        return
      }
      const inWarehouseGoods = this.selectedRows.filter((item, index, rows) => {
        if (item.status === 2) {
          return item
        }
      })
      if (inWarehouseGoods.length !== this.selectedRows.length) {
        this.$message.warning('状态不一致')
        return
      }
      const first_goods = this.selectedRows[0]
      const inWarehouseGoods2 = this.selectedRows.filter((item, index, rows) => {
        if (item.warehouse_id === first_goods.warehouse_id && item.supplier_id === first_goods.supplier_id) {
          return item
        }
      })
      if (inWarehouseGoods2.length !== this.selectedRows.length) {
        this.$message.warning('仓库或供应商不一致')
        return
      }
      this.cargo_space_form_mdl = first_goods
      this.cargo_space_form_visible = true
      this.allin_cargo_space = true
    },
    handleImport () {
      this.purchase_import_form_visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePayCancel () {
      this.purchase_declaration_visible = false
      const form = this.$refs.createPayModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      // this.$emit('onEdit', record)
      if (record.status === 1) {
        goods_purchase_update({ status: record.status + 1 }, record.id)
          .then((res) => {
            if (res.code === 1000) {
              this.$refs.table.refresh(true)
            }
            console.log(res)
          })
      } else {
        // 弹框，选择仓库的库位，入库操作 。获取仓库所对应的库位的列表
        this.cargo_space_form_mdl = record
        this.cargo_space_form_visible = true
        this.allin_cargo_space = false
      }
    },
    handlePurchaseDeclaration (record) {
      console.log(record)
      this.purchase_declaration_form_mdl = undefined
      this.purchase_declaration_id = record.id
      goods_purchase_declaration_list({ goods_purchase_id: this.purchase_declaration_id }).then(res => {
        console.log('返回的数据', res)
        if (res.success && res.data && res.data.entries && res.data.entries.length > 0) {
          this.purchase_declaration_form_mdl = res.data.entries[0]
        }
        this.purchase_declaration_visible = true
      })
    },
    handleDelet (record) {
      console.log(record.id)
      goods_purchase_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleOk (other_data) {
      const form = this.$refs.createModal.form
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          if (other_data.selectGoods !== undefined && other_data.selectGoods.open_self_life) {
            if ((values.expiration_date === undefined || values.expiration_date === '') && (values.start_date === undefined || values.start_date === '')) {
              this.$message.warning('选择截止日期')
              return
            }
          }
          other_data.selectGoods = undefined
          values.price = Math.round(values.price * 100)
          values = {
            ...values,
            ...other_data
          }
          this.confirmLoading = true
          goods_purchase_create(values).then(res => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleInCancel () {
      this.purchase_form_visible = false
      const form = this.$refs.createCommodityPurchaseModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleImportCancel () {
      this.purchase_import_form_visible = false
      const form = this.$refs.purchaseImportModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleCargoSpaceCancel () {
      this.cargo_space_form_visible = false
      const form = this.$refs.cargoSpaceModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleInOk () {
      const form = this.$refs.createCommodityPurchaseModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          values.wms_supervised_warehouse_goods_id = this.purchase_form_mdl.wms_supervised_warehouse_goods_id
          values.wms_supervised_warehouse_commodity_id = this.purchase_form_mdl.id
          values.batch = this.purchase_form_mdl.batch
          values.wms_supervised_warehouse_goods = this.purchase_form_mdl.wms_supervised_warehouse_goods
          goods_purchase_create(values).then(res => {
            this.purchase_form_visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCargoSpaceOk () {
      const form = this.$refs.cargoSpaceModal.form
      this.cargo_spaceConfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          if (this.allin_cargo_space) {
            values.goods = this.selectedRows
          } else {
            values.goods = []
            values.goods.push(this.cargo_space_form_mdl)
          }
          goods_inventory_create(values).then(res => {
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          }).catch((err) => {
              console.log(err)
              this.cargo_spaceConfirmLoading = false
            }).finally(() => {
            this.purchase_form_visible = false
            this.cargo_spaceConfirmLoading = false
            this.cargo_space_form_visible = false
            this.cargo_space_form_mdl = undefined
            this.allin_cargo_space = false
            this.selectedRows = []
            this.selectedRowKeys = []
          })
        } else {
          this.cargo_spaceConfirmLoading = false
        }
      })
    },
    handleImportOk (fileList) {
      const form = this.$refs.purchaseImportModal.form
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log(fileList, fileList.length)
          if (fileList !== undefined && fileList.length > 0) {
            // 新增
            this.purchaseImportConfirmLoading = true
            const formData = new FormData()
            formData.append('file', fileList[0])
            formData.append('supplier_id', values['supplier_id'])
            formData.append('warehouse_id', values['warehouse_id'])
            goods_purchase_import(formData).then(res => {
              console.log(res)
              this.purchase_import_form_visible = false
              this.purchaseImportConfirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
              if (res.code === 1000) {
                this.$refs.table.refresh()
              }
            })
          } else {
            this.$message.warning('导入文件')
          }
        } else {
          this.purchaseImportConfirmLoading = false
        }
      })
    },
    handleCreateCustomPurchaseCancel () {
      this.custom_purchase_create_visible = false
      const form = this.$refs.createCustomPurchaseModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleCreateCustomPurchaseOk () {
      const form = this.$refs.createCustomPurchaseModal.form
      this.purchaseDeclarationConfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          values.goods_purchase_id = this.purchase_declaration_id
          custom_goods_purchase_create(values).then(res => {
            this.custom_purchase_create_visible = false
            this.purchaseDeclarationConfirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          }).catch((err) => {
            console.log(err)
            this.purchaseDeclarationConfirmLoading = false
          })
        } else {
          this.purchaseDeclarationConfirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleWarehouseSearch (value) {
      this.dataSource_wms_warehouse = []
        this.warehouse_fetching = true
      warehouse_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_warehouse = result
      }).finally(() => {
        this.warehouse_fetching = true
      })
    },
    handleWmsWarehouseChange (value) {
      if (value === undefined) {
        this.handleWarehouseSearch(undefined)
      }
    }
  }
}
</script>
